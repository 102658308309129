import React from "react";

function About() {
    return (
        <div
            id="about-section"
            className="mt-8 p-8 w-full flex flex-col flex-wrap content-center">
            <h1 className="text-palette-4 text-4xl font-bold text-center">
                Om oss
            </h1>
            <p className="p-8 lg:px-20"> Vi på La Familia brinner för
                att skapa smakupplevelser som både glädjer och mättar. Vår foodtruck är
                ett familjeföretag som grundades med en enkel vision – att erbjuda
                högkvalitativ och välsmakande mat för alla, oavsett kostval. Med en meny
                som kombinerar smaker från tex mex och mellanöstern, erbjuder vi både
                vegetariska, veganska och kycklingalternativ – alltid med fokus på
                färska, lokala råvaror. Vi tror på mångfald i maten och på att det ska
                vara enkelt att äta gott, oavsett om du är på väg till ett evenemang, på
                jobbet eller bara vill ha något extra gott. Vår foodtruck är också den
                perfekta lösningen för catering, där vi anpassar menyn efter dina
                önskemål och ser till att din fest, företagsmiddag eller bröllop får en
                smakrik touch. Vi är stolta över att kunna skapa matglädje för alla våra
                kunder, och vi ser fram emot att vara en del av ditt nästa evenemang!
            </p>
        </div>
    );
}

export default About;
